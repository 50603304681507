import React from "react";
import { PortletBody } from "../partials/content/Portlet";
import { LaIcon } from "../partials/content/Icon";

const IconBox = ({
  link,
  target,
  color,
  icon,
  title,
  content,
  ...otherProps
}) => (
  <a
    href={link}
    target={target ? target : "_blank"}
    className={`kt-portlet kt-iconbox kt-iconbox--${color} kt-iconbox--animate-slower`}
    {...otherProps}
  >
    <PortletBody>
      <div className="kt-iconbox__body">
        {icon ? (
          <IconboxIcon>
            <LaIcon icon={icon}></LaIcon>
          </IconboxIcon>
        ) : (
          ""
        )}
        <div className="kt-iconbox__desc">
          <IconboxTitle>{title}</IconboxTitle>
          {content ? <IconboxContent>{content}</IconboxContent> : ""}
        </div>
      </div>
    </PortletBody>
  </a>
);

const IconboxIcon = ({ children, ...props }) => (
  <div className="kt-iconbox__icon" {...props}>
    {children}
  </div>
);

const IconboxTitle = ({ children }) => (
  <h3 className="kt-iconbox__title">{children}</h3>
);

const IconboxContent = ({ children }) => (
  <div className="kt-iconbox__content">{children}</div>
);

export default IconBox;
