import React from "react";
import IconBox from "../../widgets/IconBox";
// import TimelineList from "../../widgets/TimelineList";
// import Chat from "../../widgets/Chat";
import { injectIntl } from "react-intl";

const Dashboard = props => {
  const { intl } = props;
  const iconboxes = [
    {
      link: "https://analytics.google.com/analytics/web/provision/?authuser=0#/provision",
      color: "warning",
      icon: "area-chart"
    },
    {
      link: "https://search.google.com/search-console/about",
      color: "brand",
      icon: "search"
    },
    {
      link: "https://developers.google.com/speed/pagespeed/insights/?hl=HU",
      color: "success",
      icon: "dashboard"
    },
    {
      link: "mailto:info@upsolution.hu",
      color: "danger",
      icon: "envelope"
    }
  ];

  return (
    <React.Fragment>
      <div className="row">
        {iconboxes.map((box, index) => (
          <div key={index} className="col-lg-3">
            <IconBox
              {...box}
              title={intl.formatMessage({ id: `DASHBOARD.ICONBOX${index + 1}.TITLE` })}
              content={intl.formatMessage({ id: `DASHBOARD.ICONBOX${index + 1}.DESCRIPTION` })}
            />
          </div>
        ))}
      </div>
      {/* <div className="row">
        <div className="col-lg-6">
          <TimelineList />
        </div>
        <div className="col-lg-6">
          <Chat />
        </div>
      </div> */}
    </React.Fragment>
  );
};

export default injectIntl(Dashboard);
