import axios from "axios";
import { baseUrl } from "_metronic";
// import { getServices } from "app/modules/service/service.crud";

export default function filterData(modules, forceLang) {
  return axios.get(`api/admin/filter-data?modules=${modules.join(",")}`, { headers: { 'X-Lang': forceLang } }).then(({ data: { data } }) => data);
}

export const DEFAULT_LANG = {
  languageCode: "hu",
  languageId: 1
}

export const ATTACHMENT_TYPES = [
  {id: 1, name: 'HEADERIMAGE'},
  {id: 2, name: 'OGIMAGE'},
  {id: 3, name: 'LISTIMAGE'},
  {id: 4, name: 'CONTENTIMAGE'},
];

export function mapFilter(filter) {
  if (filter && filter.language) {
    filter.languageCode = filter.language;
    delete filter['language']
  }
  return filter;
}


export function getLanguages() {
  return axios.get('api/languages').then(({data}) => data);
}

export function getAttachmentTypes(moduleName) {
  return axios.get('api/filetype/'+moduleName).then(({data}) => data);
}

// const mapAttachments = (data) => 
//   data.map(a => ({...a, max: 1}));

export function mapLanguagesOptions(languages, exists, selectedLang) {
  const result = languages.map(({ id, name, isoCode }) => ({ id: id, value: isoCode, label: name, isoCode: isoCode }));
  if (exists) {
    result.map(lng => {
      if (exists.some(tr => tr.isoCode === lng.isoCode) || selectedLang === lng.isoCode) {
        lng.isDisabled = true;
      };
      return lng;
    })
  }
  return result;
}

export function mapProjectOptions(projects) {
  return sortByLabel(projects.map(({ projectId, title }) => ({ id: projectId, value: projectId, label: title })));
}
export function mapProjectCategoriesOptions(categories) {
  return sortByLabel(categories.map(({ id, name }) => ({ id, value: id, label: name })));
}
export function mapSolutions(solutions) {
  return sortByLabel(solutions.map(({solutionId, name }) => ({ id: solutionId, value: solutionId, label: name })));
}
export function mapProjectClientsOptions(clients) {
  return sortByLabel(clients.map((name, index) => ({ index, value: name, label: name })));
}

export function mapPageTypesOptions(pageTypes) {
  return pageTypes
    .map(({ id, name }) => ({ id: id, label: name, value: id }))
    .sort((a, b) => a.label.toLocaleLowerCase().localeCompare(b.label.toLocaleLowerCase()));
}

const sortByLabel = list => list.sort(({ label: a }, { label: b }) => a.localeCompare(b));

export const MODALSETTINGS = { className: "modal show fade", overlayClassName: "overlay" };

export const DEFAULTSTATUSES = intl => [
  {
    id: 1,
    label: intl.formatMessage({ id: "DEFAULTS.ACTIVE" }),
    value: 1
  },
  {
    id: 2,
    label: intl.formatMessage({ id: "DEFAULTS.INACTIVE" }),
    value: 2
  },
];

export const DEFAULTTABOPTIONS = intl => [
  {
    id: 1,
    label: intl.formatMessage({ id: "DETAILS.PROP_URL_TARGET.NEW" }),
    value: "_blank"
  },
  {
    id: 2,
    label: intl.formatMessage({ id: "DETAILS.PROP_URL_TARGET.SAME" }),
    value: "_self"
  },
];

export const MODALTABS = (intl, ids) =>
  [
    { id: 0, label: intl.formatMessage({ id: "MODAL.TAB.DATAS" }) },
    { id: 1, label: intl.formatMessage({ id: "MODAL.TAB.CONTENT" }) },
    { id: 2, label: intl.formatMessage({ id: "MODAL.TAB.RELATIONS" }) },
    { id: 3, label: intl.formatMessage({ id: "MODAL.TAB.IMAGES" }) },
    { id: 4, label: intl.formatMessage({ id: "MODAL.TAB.NUMBERS" }) }
  ].filter(tab => !ids || ids.includes(tab.id));

// export const MODALTABS = (intl, ids) =>
//   [
//     { id: 0, label: intl.formatMessage({ id: "MODAL.TAB.DATAS" }) },
//     { id: 1, label: intl.formatMessage({ id: "MODAL.TAB.CONTENT" }) },
//     { id: 3, label: intl.formatMessage({ id: "MODAL.TAB.IMAGES" }) }
//   ].filter(tab => !ids || ids.includes(tab.id));

export function message(key, _this) {
  if (typeof key === "string") {
    return _this.props.intl.formatMessage({ id: key });
  } else {
    return key.map(_key => _this.props.intl.formatMessage({ id: _key }));
  }
}

export const apiError = (error) => {
  if (error) {
    const {
      data,
      // status,
      // statusText
    } = error;
    // let reason = "";
    // for (const prop in data) {
    //   if (prop === "message") {
    //     reason = data[prop];
    //     continue;
    //   }
    //   reason += data[prop][0] + ", ";
    // }
    const reason = data.message;
    let errors = [];
    for (const prop in data.errors) {
      errors.push(...data.errors[prop]);
    }
    // return `${status} ${statusText} - ${reason}`;
    if (errors && errors.length > 0) {
      return [...errors].join('\r\n');
    }
    return `${reason.includes('Integrity constraint violation') ? 'Ez az elem nem törölhető, mert hozzá van rendelve egy modulhoz' : reason}`;
  }
  return 'Hiba a feltöltéskor (file méret túl nagy)';
};

export const mapColleaguePositionOptions = positions =>
  positions.map((position, index) => ({ id: index, label: position, value: position }));

export const addBothOption = (list, _this) => [{ id: -1, label: message("BOTH", _this), value: -1 }, ...list];

export const attachmentTypes = {
  slider: [{ id: 2, name: "picture", opts: { size: "1920x1080" } }],
  services: [{ id: 5, name: "icon", opts: { type: "svg" } }],
  projects: [
    // { id: 1, name: "header", opts: { size: "1920x530" } },
    // { id: 3, name: "list", opts: { size: "640x480" } },
    { id: 3, name: "list", opts: { size: "640x800" } },
    // { id: 2, name: "picture", opts: { size: "800x450", quantity: Infinity } },
    // { id: 4, name: "gallery", opts: { size: { small: "263x150", big: "800x450" }, quantity: Infinity, keepOriginal: true } }
  ],
  posts: [
    // { id: 1, name: "header", opts: { size: "1920x530" } },
    { id: 3, name: "list", opts: { size: "560x320" } },
    // { id: 2, name: "picture", opts: { size: "800x450", quantity: Infinity } },
    // { id: 4, name: "gallery", opts: { size: { small: "263x150", big: "800x450" }, quantity: Infinity, keepOriginal: true } }
  ],
  colleagues: [{ id: 3, name: "list", opts: { size: "190x220", type: "png" } }]
};

export function assembleLink(link) {
  if (!link) {
    return false;
  }
  return baseUrl.substring(0, baseUrl.length - 1) + link;
}

export function handleFormChange(nv, obj, languageIndex) {
  // if (!obj.translations[languageIndex] && nv.translations[languageIndex]) {
  //   obj.translations[languageIndex] = { images: [] };
  // }
  // if (
  //   nv.translations[languageIndex] &&
  //   obj.translations[languageIndex] &&
  //   nv.translations[languageIndex].languageCode &&
  //   // eslint-disable-next-line eqeqeq
  //   obj.translations[languageIndex].languageCode != nv.translations[languageIndex].languageCode
  // ) {
  //   obj.translations[languageIndex].languageCode = nv.translations[languageIndex].languageCode;
  // }
  // // obj.translations = nv.translations;
  obj = nv;
  return obj;
}

export function checkNewImages(obj) { return obj.translations.some(translation => translation.images && translation.images.length && translation.images.some(image => image.files && Object.keys(image.files).length)); }
