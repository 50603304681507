import React, { Component } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../../store/ducks/auth.duck";
import { requestPassword } from "../../crud/auth.crud";
import clsx from "clsx";

class ForgotPassword extends Component {
  state = { isRequested: false };

  render() {
    const { intl } = this.props;
    const { isRequested } = this.state;

    if (isRequested) {
      return <Redirect to="/auth" />;
    }

    return (
      <div className="kt-login__forgot" style={{ display: "block" }}>
        <div className="kt-login__head">
          <h3 className="kt-login__title">
            <FormattedMessage id="AUTH.FORGOT.TITLE" />
          </h3>
          <div className="kt-login__desc">
            <FormattedMessage id="AUTH.FORGOT.DESC" />
          </div>
        </div>

        <Formik
          initialValues={{ email: "" }}
          validate={values => {
            const errors = {};

            if (!values.email) {
              errors.email = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD"
              });
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
              errors.email = intl.formatMessage({
                id: "AUTH.VALIDATION.INVALID_FIELD"
              });
            }

            return errors;
          }}
          onSubmit={(values, { setStatus, setSubmitting }) => {
            requestPassword(values.email)
              .then(() => {
                this.setState({ isRequested: true });
              })
              .catch(() => {
                setSubmitting(false);
                setStatus(intl.formatMessage({ id: "AUTH.VALIDATION.NOT_FOUND" }, { name: values.email }));
              });
          }}
        >
          {({ values, status, touched, errors, handleChange, handleBlur, handleSubmit }) => (
            <form onSubmit={handleSubmit} className="kt-form" action="">
              {status && (
                <div role="alert" className="alert alert-danger">
                  <div className="alert-text">{status}</div>
                </div>
              )}
              <div className="input-group">
                <input
                className={clsx("form-control", { "is-invalid": Boolean(touched.email && errors.email) })}
                  type="text"
                  placeholder={intl.formatMessage({id: 'AUTH.INPUT.EMAIL'})}
                  name="email"
                  id="kt_email"
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                />
                {Boolean(touched.email && errors.email) && (
                  <div className="invalid-feedback">{touched.email && errors.email}</div>
                )}
              </div>
              <div className="kt-login__actions">
                <button type="submit" id="kt_login_forgot_submit" className="btn btn-brand btn-pill kt-login__btn-primary">
                  küldés
                </button>
                &nbsp;&nbsp;
                <Link to="/auth">
                  <button id="kt_login_forgot_cancel" className="btn btn-secondary btn-pill kt-login__btn-secondary">
                    mégsem
                  </button>
                </Link>
              </div>
            </form>
          )}
        </Formik>
      </div>
    );
  }
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword));
