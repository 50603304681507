export default {
  header: {
    self: {},
    items: [
      // {
      //   title: "GYIK",
      //   root: true,
      //   alignment: "left",
      //   page: "faq",
      //   translate: "MENU.FAQ"
      // },
      {
        title: "Hibabejelentés",
        root: true,
        alignment: "left",
        mailto: "mailto:info@upsolution.hu",
        translate: "MENU.SENDTICKET",
      },
    ],
  },
  aside: {
    self: {},
    items: [
      {
        title: "Dashboard",
        root: true,
        icon: "la la-home",
        page: "dashboard",
        translate: "MENU.DASHBOARD",
      },
      {
        title: "Menu",
        root: true,
        icon: "flaticon-layers",
        alignment: "left",
        toggle: "click",
        bullet: "dot",
        translate: "MENU.PAGES",
        submenu: [
          {
            title: "Oldalak",
            page: "menu/pages",
            translate: "MENU.PAGES",
          },
          {
            title: "Menüpontok rendezése",
            page: "menu/structure",
            translate: "MENU.STRUCTURE",
          },
          {
            title: "Sitemap generálása",
            page: "menu/sitemap",
            translate: "MENU.MENU.SITEMAP",
          },
        ],
      },
      { section: "Modules", translate: "MENU.SECTION.MODULES" },
      {
        title: "Hírek",
        root: true,
        icon: "flaticon2-open-text-book",
        alignment: "left",
        toggle: "click",
        bullet: "dot",
        translate: "MENU.POSTS",
        submenu: [
          {
            title: "Hírek",
            translate: "MENU.POSTS",
            page: "posts",
          },
          // {
          //   title: "Hírek rendezése",
          //   translate: "MENU.POSTS.ORDERING",
          //   page: "posts/ordering",
          // },
          {
            title: "Kategóriák",
            translate: "MENU.POSTS.CATEGORIES",
            page: "posts/categories",
          },
          {
            title: "Kategóriák rendezése",
            translate: "MENU.POSTS.CATEGORIES.ORDERING",
            page: "posts/categories/ordering",
          },
        ],
      },
      {
        title: "Megoldásaink",
        root: true,
        icon: "flaticon-map",
        alignment: "left",
        toggle: "click",
        bullet: "dot",
        translate: "MENU.SOLUTIONS",
        submenu: [
          {
            title: "Megoldások",
            translate: "MENU.SOLUTION",
            page: "solutions",
          },
          {
            title: "Megoldások rendezése",
            translate: "MENU.SOLUTIONS.ORDERING",
            page: "solutions/ordering",
          }
        ],
      },
      {
        title: "Projektek",
        root: true,
        icon: "flaticon-folder-1",
        alignment: "left",
        toggle: "click",
        bullet: "dot",
        translate: "MENU.PROJECTS",
        submenu: [
          {
            title: "Projektek",
            translate: "MENU.PROJECTS",
            page: "projects",
          },
          {
            title: "Projektek rendezése",
            translate: "MENU.PROJECTS.ORDERING",
            page: "projects/ordering",
          } /*,
            {
              title: "Kategóriák",
              translate: "MENU.PROJECTS.CATEGORIES",
              page: "projects/categories"
            }*/,
        ],
      },
      {
        title: "Partnerek",
        root: true,
        icon: "flaticon-users",
        alignment: "left",
        toggle: "click",
        bullet: "dot",
        translate: "MENU.PARTNERS",
        submenu: [
          {
            title: "Projektek",
            translate: "MENU.PARTNERS",
            page: "partners",
          },
          {
            title: "Projektek rendezése",
            translate: "MENU.PARTNERS.ORDERING",
            page: "partners/ordering",
          } /*,
            {
              title: "Kategóriák",
              translate: "MENU.PROJECTS.CATEGORIES",
              page: "projects/categories"
            }*/,
        ],
      },
      {
        title: "Kompetenciák",
        root: true,
        icon: "la la-list-ol",
        alignment: "left",
        toggle: "click",
        bullet: "dot",
        translate: "MENU.COMPETENCE",
        submenu: [
          {
            title: "Kompetenciák",
            translate: "MENU.COMPETENCE",
            page: "competence",
          },
          {
            title: "Kompetenciák rendezése",
            translate: "MENU.COMPETENCE.ORDERING",
            page: "competence/ordering",
          } /*,
            {
              title: "Kategóriák",
              translate: "MENU.PROJECTS.CATEGORIES",
              page: "projects/categories"
            }*/,
        ],
      } ,
      /*{
        title: "Pozíciók",
        root: true,
        icon: "la la-building",
        alignment: "left",
        toggle: "click",
        bullet: "dot",
        translate: "POSITION.POSITION",
        submenu: [
          {
            title: "Pozíciók",
            translate: "POSITION.POSITION",
            page: "position",
          },
          {
            title: "Pozíciók rendezése",
            translate: "POSITION_ORDERING.TITLE",
            page: "position/ordering",
          },
          {
            title: "Főkategóriák",
            translate: "POSITION.MAINCATEGORY",
            page: "position/main-categories",
          },
          {
            title: "Alkategóriák",
            translate: "POSITION.SUBCATEGORY",
            page: "position/sub-categories",
          },
        ],
      }, 
      {
        title: "Pályázatok",
        root: true,
        icon: "flaticon-line-graph",
        alignment: "left",
        toggle: "click",
        bullet: "dot",
        translate: "COMPETITIONS.COMPETITIONS",
        submenu: [
          {
            title: "Pályázatok",
            translate: "COMPETITIONS.COMPETITIONS",
            page: "competitions",
          },
          {
            title: "Pályázatok rendezése",
            translate: "COMPETITIONS_ORDERING.TITLE",
            page: "competitions/ordering",
          },
        ],
      },*/
      { section: "Components", translate: "MENU.SECTION.COMPONENTS" },
      {
        title: "Slider",
        root: true,
        icon: "la la-clone",
        alignment: "left",
        toggle: "click",
        bullet: "dot",
        translate: "MENU.SLIDER",
        submenu: [
          {
            title: "Slider",
            translate: "MENU.SLIDER",
            page: "slider",
          },
          {
            title: "Slider rendezése",
            translate: "MENU.SLIDER.ORDERING",
            page: "slider/ordering",
          },
        ],
      },
      {
        title: "Munkatársak",
        root: true,
        icon: "flaticon2-group",
        alignment: "left",
        toggle: "click",
        bullet: "dot",
        translate: "MENU.COLLEAGUES",
        submenu: [
          {
            title: "Munkatársak",
            translate: "MENU.COLLEAGUES",
            page: "colleagues",
          },
          {
            title: "Munkatársak rendezése",
            translate: "MENU.COLLEAGUES.ORDERING",
            page: "colleagues/ordering",
          },
        ],
      },
      {
        title: "Közreműködők",
        root: true,
        icon: "flaticon-profile",
        translate: "MENU.CONTRIBUTORS",
        page: "contributors",
      },
      {
        title: "Vélemények",
        root: true,
        icon: "flaticon-notes",
        translate: "MENU.OPINIONS",
        page: "opinions",
      },
      {
        title: "Szolgáltatások",
        root: true,
        icon: "la la-list-ul",
        translate: "MENU.SERVICES",
        page: "services",
      },
      {
        title: "Részletek",
        root: true,
        icon: "flaticon2-list-1",
        translate: "MENU.DETAILS",
        page: "details",
      },
      {
        title: "Idővonal",
        root: true,
        icon: "flaticon-calendar-with-a-clock-time-tools",
        alignment: "left",
        toggle: "click",
        bullet: "dot",
        translate: "MENU.TIMELINE",
        submenu: [
          {
            title: "Idővonal",
            translate: "MENU.TIMELINE",
            page: "timeline",
          },
          {
            title: "Idővonal rendezése",
            translate: "MENU.TIMELINE.ORDERING",
            page: "timeline/ordering",
          },
        ],
      },
      /*{
        title: "Szolgaltatasok",
        root: true,
        icon: "la la-list-ul",
        alignment: "left",
        toggle: "click",
        bullet: "dot",
        translate: "MENU.SERVICES",
        submenu: [
          {
            title: "Szolgaltatasok",
            translate: "MENU.SERVICES",
            page: "services",
          },
          {
            title: "Szolgaltatasok rendezése",
            translate: "MENU.SERVICES.ORDERING",
            page: "services/ordering",
          },
        ],
      },*/
      { section: "Admin", translate: "MENU.SECTION.ADMIN" },
      {
        title: "Felhasználók",
        root: true,
        icon: "la la-users",
        translate: "MENU.USERS",
        page: "users",
      },
      // {
      //   title: "Megjelenítési formák",
      //   root: true,
      //   icon: "la la-desktop",
      //   translate: "MENU.PAGETYPES",
      //   page: "pagetypes",
      // },
      {
        title: "Képkezelő",
        root: true,
        icon: "flaticon2-photograph",
        translate: "MENU.GALLERY",
        page: "imagemanager"
      },
      {
        title: "Nyelvek",
        root: true,
        icon: "la la-globe",
        translate: "MENU.LANGUAGES",
        page: "languages"
      },
      {
        title: "Szavak",
        root: true,
        icon: "la la-language",
        translate: "MENU.WORDS",
        page: "words",
      },
    ],
  },
};
