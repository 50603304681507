import React from "react";
import { Link } from "react-router-dom";
// eslint-disable-next-line no-unused-vars
import { Redirect, Route, Switch } from "react-router-dom";
// import Registration from "./Registration";
import ForgotPassword from "./ForgotPassword";
import Login from "./Login";
import ActivateAccount from "app/pages/auth/ActivateAccount";
import ResetPassword from "app/pages/auth/ResetPassword";

export default function AuthPage({match}) {
  return (
    <div className="kt-grid kt-grid--ver kt-grid--root">
      <div className="kt-grid kt-grid--hor kt-grid--root  kt-login kt-login--v4 kt-login--signin" id="kt_login">
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" style={{ backgroundImage: "url(/media/bg/bg-2.jpg)" }}>
          <div className="kt-grid__item kt-grid__item--fluid kt-login__wrapper">
            <div className="kt-login__container">
              <div className="kt-login__logo">
                <Link to="/">
                  <img alt="logo" src="/media/logos/upsolution-logo.svg" />
                </Link>
              </div>
              <Switch>
                <Redirect from={match.path} exact to={`${match.path}/login`} />

                <Route path={`${match.path}/login`} component={Login} />
                {/*<Route path="/auth/registration" component={Registration} />*/}
                <Route path={`${match.path}/forgot-password`} component={ForgotPassword} />
                <Route path={`${match.path}/activate/:token`} component={ActivateAccount} />
                {/* <Route path={`${match.path}/change-credential/:token`} component={ActivateAccount} /> */}
                <Route path={`${match.path}/reset-password/:token`} component={ResetPassword} />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
