import axios from "axios";

export const LOGIN_URL = "api/auth";
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgotCredential";
export const ACTIVATE_URL = "api/register/verifyAccount"; 
export const RESET_PASSWORD_URL = "api/auth/resetCredential";

export const ME_URL = "api/auth/me";

export function login(username, password, type) {
  return axios.post(LOGIN_URL, { username, password, type });
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email, type: 'password' });
}

export function activateAccount({ password, passwordConfirm }, token) {
  return axios.post(ACTIVATE_URL, { type: 'password', credential: password, verificationCode: token });
}
export function resetPassword({ password, passwordConfirm, email }, token) {
  return axios.post(RESET_PASSWORD_URL, { type: 'password', credential: password, resetToken: token });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}
