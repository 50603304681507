import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { LayoutSplashScreen } from "../../_metronic";
import Dashboard from "./home/Dashboard";

export default function HomePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          // <Redirect exact from="/" to="/dashboard" />
          <Redirect exact from="/" to="/dashboard" />
        }
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/menu" component={lazy(() => import("./menu/MenuPage"))} />
        <Route path="/languages" component={lazy(() => import("./language/LanguagePage"))} />
        <Route path="/users" component={lazy(() => import("./user/UserPage"))} />
        {/* <Route path="/pagetypes" component={lazy(() => import("./pagetype/PageTypePage"))} /> */}
        <Route path="/words" component={lazy(() => import("./word/WordPage"))} />
        <Route path="/posts" component={lazy(() => import("app/modules/post/PostPage"))} />
        <Route path="/projects" component={lazy(() => import("app/modules/project/ProjectPage"))} />
        <Route path="/slider" component={lazy(() => import("app/modules/slider/SliderPage"))} />
        <Route path="/services" component={lazy(() => import("app/modules/service/ServicePage"))} />
        <Route path="/position" component={lazy(() => import("app/modules/position/PositionPage"))} />
        <Route path="/colleagues" component={lazy(() => import("app/modules/colleague/ColleaguePage"))} />
        <Route path="/competitions" component={lazy(() => import("app/modules/competition/CompetitionPage"))} />
        <Route path="/solutions" component={lazy(() => import("app/modules/solutions/SolutionPage"))} />
        <Route path="/details" component={lazy(() => import("app/modules/details/DetailsPage"))} />
        <Route path="/opinions" component={lazy(() => import("app/modules/opinions/OpinionsPage"))} />
        <Route path="/competence" component={lazy(() => import("app/modules/competence/CompetencePage"))} />
        <Route path="/contributors" component={lazy(() => import("app/modules/contributors/ContributorsPage"))} />
        <Route path="/imagemanager" component={lazy(() => import("app/modules/image-manager/ImageManager"))} />
        <Route path="/timeline" component={lazy(() => import("app/modules/timeline/TimelinePage"))} />
        <Route path="/partners" component={lazy(() => import("app/modules/partners/PartnersPage"))} />
        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
}
