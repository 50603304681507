import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";

export const actionTypes = {
  ApiError: "[Api] Action",
  Close: "[Close] Action"
};

const initialErrorState = {
  show: false,
  info: undefined
};

export const errorReducer = persistReducer({ storage, key: "errorHandler", whitelist: ["info"] }, (state = initialErrorState, action) => {
  switch (action.type) {
    case actionTypes.ApiError:
      const { info } = action.payload;
      return { show: true, info: info };
      case actionTypes.Close:
        return {show: false, info: undefined};
    default:
      return state;
  }
});

export const actions = {
  apiError: info => ({ type: actionTypes.ApiError, payload: { info } }),
  close: () => ({type: actionTypes.Close, payload: undefined})
};

export function* saga() {
    yield takeLatest(actionTypes.ApiError, function* showError(){
        yield put(actions.apiError())
    })
    yield takeLatest(actionTypes.Close, function* close(){
        yield put(actions.close())
    })
}
