import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IntlProvider } from "react-intl";
import "@formatjs/intl-pluralrules/polyfill";
import "@formatjs/intl-pluralrules/dist/locale-data/en";
import "@formatjs/intl-pluralrules/dist/locale-data/hu";
import Axios from "axios";

export default function I18nProvider({ children }) {
  const locale = useSelector(({ i18n }) => i18n.lang);
  const [messages, setMessages] = useState(null);

  useEffect(() => {
    Promise.all([
      Axios.get(`${process.env.PUBLIC_URL}/messages/${locale}.json`),
      Axios.get(`${process.env.PUBLIC_URL}/messages/${locale}_error.json`),
    ]).then(res => res.map((({ data }) => data))).then(([front, api]) => {
      setMessages({ ...front, ...api });
    })
  }, [locale]);

  if (messages) {
    return (
      <IntlProvider key={locale} defaultLocale="en" locale={locale} messages={messages}>
        {children}
      </IntlProvider>
    );
  } else {
    return (<div></div>);
  }
}
