import React, { Component } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../../store/ducks/auth.duck";
import { activateAccount } from "../../crud/auth.crud";
import clsx from "clsx";
import { apiError } from "app/crud/common.crud";

class ActivateAccount extends Component {
  state = { isRequested: false };

  constructor(props) {
    super(props);

    this.state = { loading: false, loadingButtonStyle: { paddingRight: "2.5rem" } };
  }

  enableLoading = () => {
    this.setState({ loading: true, loadingButtonStyle: { paddingRight: "3.5rem" } });
  };

  disableLoading = () => {
    this.setState({ loading: false, loadingButtonStyle: { paddingRight: "2.5rem" } });
  };

  render() {
    const { intl, match } = this.props;
    const { isRequested, loading, loadingButtonStyle } = this.state;

    if (isRequested || !match.params || match.params.length === 0) {
      return <Redirect to="/auth" />;
    }

    return (
      <div className="kt-login__forgot" style={{ display: "block" }}>
        <div className="kt-login__head">
          <h3 className="kt-login__title">
            <FormattedMessage id="AUTH.ACTIVATEACCOUNT" />
          </h3>
          <div className="kt-login__desc">
            <FormattedMessage id="AUTH.ACTIVATEACCOUNT.DESC" />
          </div>
        </div>

        <Formik
          initialValues={{ passwordConfirm: "", password: "" }}
          validate={values => {
            const errors = {};

            if (!values.password) {
              errors.password = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD"
              });
            } else if (
              !/^(?=.*[a-zöüóőúűéá])(?=.*[A-ZÍÖÜÓŐÚŰÉÁ])(?=.*\d)[A-Za-z\d@$!%*?&öüóőúűéáíÍÖÜÓŐÚŰÉÁ]{8,}$/i.test(
                values.password
              )
            ) {
              errors.password = intl.formatMessage({
                id: "AUTH.PASSWORD.INVALID_FIELD"
              });
            }

            if (!values.passwordConfirm) {
              errors.passwordConfirm = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD"
              });
            }
            return errors;
          }}
          onSubmit={(values, { setStatus, setSubmitting }) => {
            this.enableLoading();
            activateAccount(values, match.params.token)
              .then(() => {
                this.disableLoading();
                this.setState({ isRequested: true });
              })
              .catch(reason => {
                this.disableLoading();
                setSubmitting(false);
                if (reason && reason.response && reason.response.data) {
                  setStatus(typeof reason.response.data === "string" ? reason.response.data : apiError(reason.response));
                }
              });
          }}
        >
          {({ values, status, touched, errors, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit} className="kt-form" action="">
              {status && (
                <div role="alert" className="alert alert-danger">
                  <div className="alert-text">{status}</div>
                </div>
              )}
              <div className="input-group">
                <input
                  className={clsx("form-control", { "is-invalid": Boolean(touched.password && errors.password) })}
                  type="password"
                  placeholder={intl.formatMessage({
                    id: "AUTH.INPUT.PASSWORD"
                  })}
                  name="password"
                  id="password"
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                />
                {Boolean(touched.password && errors.password) && (
                  <div className="invalid-feedback">{touched.password && errors.password}</div>
                )}
              </div>
              <div className="input-group">
                <input
                  className={clsx("form-control", { "is-invalid": Boolean(touched.password && errors.password) })}
                  type="password"
                  placeholder={intl.formatMessage({
                    id: "AUTH.INPUT.CONFIRM_PASSWORD"
                  })}
                  name="passwordConfirm"
                  id="passwordConfirm"
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.passwordConfirm}
                />
                {Boolean(touched.passwordConfirm && errors.passwordConfirm) && (
                  <div className="invalid-feedback">{touched.passwordConfirm && errors.passwordConfirm}</div>
                )}
              </div>
              <div className="kt-login__actions">
                <button
                  type="submit"
                  id="kt_login_forgot_submit"
                  disabled={isSubmitting}
                  className={`btn btn-brand btn-pill kt-login__btn-primary ${clsx({
                    "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                  })}`}
                  style={loadingButtonStyle}
                >
                  {intl.formatMessage({ id: "AUTH.GENERAL.SUBMIT_BUTTON" })}
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    );
  }
}

export default injectIntl(connect(null, auth.actions)(ActivateAccount));
