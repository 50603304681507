import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import clsx from "clsx";
import * as auth from "../../store/ducks/auth.duck";
import { login } from "../../crud/auth.crud";

function Login(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem"
  });

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };

  return (
    <div className="kt-login__signin">
      <div className="kt-login__head">
        <h3 className="kt-login__title">
          <FormattedMessage id="AUTH.LOGIN.TITLE" />
        </h3>
      </div>
      <Formik
        initialValues={{
          username: "",
          password: "",
          type: "password"
        }}
        validate={values => {
          const errors = {};

          if (!values.username) {
            // https://github.com/formatjs/react-intl/blob/master/docs/API.md#injection-api
            errors.username = intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD"
            });
          } 
          // else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.username)) {
          //   errors.username = intl.formatMessage({
          //     id: "AUTH.VALIDATION.INVALID_FIELD"
          //   });
          // }

          if (!values.password) {
            errors.password = intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD"
            });
          }

          return errors;
        }}
        onSubmit={(values, { setStatus, setSubmitting }) => {
          enableLoading();
          setTimeout(() => {
            login(values.username, values.password, values.type)
              .then(
                (data) => {
                  disableLoading();
                  props.login(data.data.token);
                }
              )
              .catch((err) => {
                disableLoading();
                setSubmitting(false);
                setStatus(
                  intl.formatMessage({
                    id: "AUTH.VALIDATION.INVALID_LOGIN"
                  })
                );
              });
          }, 1000);
        }}
      >
        {({ values, status, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
          <form noValidate={true} autoComplete="off" className="kt-form" onSubmit={handleSubmit}>
            {status ? (
              <div role="alert" className="alert alert-danger">
                <div className="alert-text">{status}</div>
              </div>
            ) : (
              ""
            )}
            <div className="input-group">
              <input
                className="form-control"
                type="text"
                placeholder={intl.formatMessage({id: 'AUTH.INPUT.EMAIL'})}
                name="username"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.username}
              />
            </div>
            <div className="input-group">
              <input
                className="form-control"
                type="password"
                placeholder={intl.formatMessage({id: 'AUTH.INPUT.PASSWORD'})}
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password}
              />
            </div>
            <div className="row kt-login__extra">
              <div className="col">
                {/* <label className="kt-checkbox">
                  <input type="checkbox" name="remember" /> bejelentkezve maradok
                  <span></span>
                </label> */}
              </div>
              <div className="col kt-align-right">
                <Link type="" to="/auth/forgot-password" id="kt_login_forgot" className="kt-login__link">
                {intl.formatMessage({id: 'AUTH.FORGOT.TITLE'})}
                </Link>
              </div>
            </div>
            <div className="kt-login__actions">
              <button
                id="kt_login_signin_submit"
                type="submit"
                disabled={isSubmitting}
                className={`btn btn-brand btn-pill kt-login__btn-primary ${clsx({
                  "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                })}`}
                style={loadingButtonStyle}
              >
              {intl.formatMessage({id: 'AUTH.LOGIN.BUTTON'})}
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
